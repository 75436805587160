import "./ConnectWallet.scss";
import { FC, useEffect, useState } from "react";
import { Box, Icon, Modal } from "@mui/material";
import { ScaleLoader } from "react-spinners";

import ContractService from "../../Services/ContractService";
import AuthService from "../../Services/AuthService";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

import { configStore, userStore } from "../../redux/store";
import { setUserAction } from "../../redux/user/actions";
import { setGameStatusAction } from "../../redux/config/actions";

const passwordReg = [
  {
    name: "uppercase",
    reg: /.*[A-Z].*/,
    desc: "At least one uppercase letter",
  },
  {
    name: "lowercase",
    reg: /.*[a-z].*/,
    desc: "At least one lowercase letter",
  },
  {
    name: "number",
    reg: /.*\d.*/,
    desc: "At least one number",
  },
  {
    name: "long",
    reg: /^.{6,}$/,
    desc: "At least 6 characters long",
  },
];

const ConnectWallet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const [activeSegment, setActiveSegment] = useState("login");
  const [switchAnimation, setSwitchAnimation] = useState(false);
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [wallet, setWallet] = useState("");
  const [otp, setOtp] = useState("");
  const [verifyAction, setVerifyAction] = useState("");
  const [currentBalance, setCurrentBalance] = useState(0);
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassRegister, setConfirmPassRegister] = useState("");

  const [user, setUser] = useState<any>(null);

  const [openPassPopper, setOpenPassPopper] = useState(false);
  let [passValid, setPassValid] = useState<any>({
    uppercase: false,
    lowercase: false,
    number: false,
    long: false,
  });

  useEffect(() => {
    const userSubs = userStore.subscribe(() => {
      setUser(userStore.getState());
    });

    if (localStorage.getItem("spicaToken")) {
      verifyUser();
    }

    if (localStorage.getItem("connectedOnce")) {
      connect();
    }
    return () => {
      userSubs();
    };
  }, []);

  const setUserWindowId = async () => {
    return AuthService.setUserWindowId().catch(console.log);
  };

  useEffect(() => {
    handleFormChange();
  }, [formData]);

  const verifyUser = async () => {
    await AuthService.verifyToken().catch(() =>
      localStorage.removeItem("spicaToken")
    );
  };
  const handleFormChange = () => {
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var regPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

    for (let item of passwordReg) {
      if (item.reg.test(formData.password)) {
        setPassValid({ ...passValid, [item.name]: true });
        passValid = { ...passValid, [item.name]: true };
      } else {
        setPassValid({ ...passValid, [item.name]: false });
        passValid = { ...passValid, [item.name]: false };
      }
    }
    if (
      (activeSegment === "login" && !recoveryModal && !newPasswordModal) ||
      (activeSegment === "register" && regPass.test(formData.password)) ||
      (recoveryModal && regEmail.test(formData.email)) ||
      (newPasswordModal && regPass.test(formData.password))
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const switchRecoveryModal = (value: boolean) => {
    setRecoveryModal(value);
    setFormData({ email: "", password: "" });
    // !value && setSwitchAnimation(false);
  };

  const switchSegment = (value: string) => {
    if (value !== activeSegment) {
      setFormData({ email: "", password: "" });
      setActiveSegment(value);
      setSwitchAnimation(!switchAnimation);
    }
  };

  const connect = async () => {
    setIsLoading(true);
    localStorage.setItem("connectedOnce", "true");
    let successConnection = true;
    try {
      await ContractService.sendConnectionRequest();
    } catch (error) {
      setIsLoading(false);
      successConnection = false;
      console.log(error);
    }
    await ContractService.setUpContract().catch(() => {
      setIsLoading(false);
      successConnection = false;
      return;
    });

    if (!successConnection) return;

    try {
      const userAddress = await ContractService.getSigner().getAddress();
      const connection = await ContractService.getConnectedContract(
        ContractService.getSolidities().WLSolidity
      );
      let currentBalanceTemp = await connection.balanceOf(userAddress);
      currentBalanceTemp = Number(currentBalanceTemp) / 1e18;
      setCurrentBalance(currentBalanceTemp);

      if (!userAddress) return;
      setWallet(userAddress);

      if (localStorage.getItem("addedWislToken") != "true")
        await ContractService.addWisltoMetamask();
      localStorage.setItem("addedWislToken", "true");

      if (localStorage.getItem("spicaToken")) {
        let userRes = await AuthService.getUser();

        userRes["currentBalance"] = currentBalanceTemp;
        userRes["token"] = localStorage.getItem("spicaToken");
        userStore.dispatch(setUserAction(userRes));
      }

      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      toast.error(
        err.data
          ? err.data.message.split(":")[err.data.message.split(":").length - 1]
          : err.message.split(":")[err.message.split(":").length - 1]
      );
    }
  };

  const login = async () => {
    if (localStorage.getItem("spicaToken") || userStore.getState()) {
      toast.success("You are already connected. Page will be reloaded");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }
    setIsLoading(true);
    try {
      const res: any = await AuthService.login(formData.password, wallet);
      toast.success("Login success");
      modalOnClose();
      localStorage.setItem("spicaToken", res.data.token);
      res.data["currentBalance"] = currentBalance;
      userStore.dispatch(setUserAction(res.data));
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const register = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.register(formData.password, wallet);
      toast.success(res.data.message);
      setVerifyAction("register");
      switchSegment("login");
      // setVerificationModal(true);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const modalOnClose = () => {
    setAuthModal(false);
    switchRecoveryModal(false);
    setVerificationModal(false);
    setNewPasswordModal(false);
    setOtp("");
    switchSegment("login");
  };

  const verifyCode = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.verifyCode(
        formData.email,
        otp,
        verifyAction
      );
      toast.success(res.data.message);
      setVerificationModal(false);
      if (verifyAction == "forgot_password") {
        setNewPasswordModal(true);
      }
      switchSegment("login");
      setVerificationModal(false);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const recoveryPassword = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.recoveryPassword(formData.email);
      toast.success(res.data.message);
      setVerifyAction("forgot_password");
      setVerificationModal(true);
      setRecoveryModal(false);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const setNewPassword = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.setNewPassword({ ...formData, wallet });
      toast.success(res.data.message);
      setNewPasswordModal(false);
      setFormData({ email: "", password: "" });
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const resendVerificationCode = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.resendVerificationCode(
        formData.email,
        verifyAction
      );
      toast.success(res.data.message);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleOtpChange = (otp: string) => setOtp(otp);

  const handleMainButton = async () => {
    if (!wallet) {
      connect();
    } else {
      if (localStorage.getItem("spicaToken") && userStore.getState()) {
        if (
          !userStore.getState().ships ||
          userStore.getState().ships.length == 0
        )
          toast.error("You must have at least one ship!");
        else {
          configStore.dispatch(setGameStatusAction("start"));
          const window_id = await setUserWindowId();
          userStore.dispatch(
            setUserAction({ ...userStore.getState(), window_id })
          );
          configStore.dispatch(setGameStatusAction("playing"));
        }
      } else {
        setAuthModal(true);
      }
    }
  };

  const renderNewPasswordModal = () => {
    return (
      <div className="ModalBox ConnectModalBox">
        <div className="FormHeader">
          <h3 className="ActiveSegment">Reset Password</h3>
        </div>
        <div className={`FormBody ${switchAnimation && "Animation"}`}>
          <div className="BodySegment">
            <input
              value={formData.password}
              onFocus={() => setOpenPassPopper(true)}
              onBlur={() => setOpenPassPopper(false)}
              placeholder="New Password"
              type="password"
              className="PrimaryInput"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
                if (e.target.value == "") setOpenPassPopper(false);
              }}
            />
            <input
              value={confirmPass}
              placeholder="Confirm Password"
              type="password"
              className="PrimaryInput"
              onChange={(e) => setConfirmPass(e.target.value)}
            />
            {renderButton(
              "Reset Password",
              setNewPassword,
              !formIsValid || isLoading || confirmPass != formData.password
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderVerificationModal = () => {
    return (
      <div className="ModalBox ConnectModalBox">
        <div className="FormHeader">
          <h3 className="ActiveSegment">Enter Verification Code</h3>
        </div>
        <div className={`FormBody ${switchAnimation && "Animation"}`}>
          <div className="BodySegment">
            <OtpInput
              inputStyle={{
                width: "40px",
                height: "41px",
                margin: "0 6px",
                background: "var(--theme-background)",
                border: "2px solid var(--secondary-color-light)",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
              value={otp}
              onChange={handleOtpChange}
              numInputs={5}
              isInputNum
            />
            {renderButton(
              "Verify Code",
              verifyCode,
              otp.length < 5 || isLoading
            )}
          </div>
        </div>
        <div className="FormFooter">
          <div className="Info" onClick={() => resendVerificationCode()}>
            Resend Code
          </div>
        </div>
      </div>
    );
  };

  const renderRecoveryModal = () => {
    return (
      <div className="ModalBox ConnectModalBox">
        <div className="FormHeader">
          <h3 className="ActiveSegment">Password Recovery</h3>
        </div>
        <div className={`FormBody ${switchAnimation && "Animation"}`}>
          <div className="BodySegment">
            <input
              placeholder="E-mail"
              className="PrimaryInput"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            {renderButton(
              "Recover Password",
              recoveryPassword,
              !formIsValid || isLoading
            )}
          </div>
        </div>
        <div className="FormFooter">
          <div className="Info" onClick={() => switchRecoveryModal(false)}>
            Go Back
          </div>
        </div>
      </div>
    );
  };

  const renderButton = (title: string, click: () => {}, disabled: boolean) => {
    return (
      <button className="SecondaryBtn" onClick={click} disabled={disabled}>
        {isLoading ? (
          <ScaleLoader
            color="white"
            height={20}
            width={2}
            radius={2}
            margin={2}
          />
        ) : (
          title
        )}
      </button>
    );
  };

  const renderPassPopper = () => {
    return passwordReg.map((item, index) => (
      <span key={index}>
        <Icon className={`${passValid[item.name] && "Checked"} PassIcon`}>
          {passValid[item.name] ? "check_circle" : "cancel"}
        </Icon>
        {item.desc}
      </span>
    ));
  };

  return (
    <div className="ConnectContainer">
      {user && !user.can_login ? (
        <div>The testnet is finished!</div>
      ) : (
        <button
          className="CustomBtn"
          onClick={() => handleMainButton()}
          disabled={isLoading}
        >
          {!wallet
            ? "Connect"
            : localStorage.getItem("spicaToken")
            ? "Play"
            : "Login"}
        </button>
      )}

      <Modal open={showAuthModal} onClose={() => modalOnClose()}>
        <Box>
          {openPassPopper && (
            <div className={`PassPopper ${newPasswordModal && "NewPass"}`}>
              {renderPassPopper()}
            </div>
          )}
          {!newPasswordModal && !verificationModal && !recoveryModal && (
            <div className="ModalBox ConnectModalBox">
              <div className="FormHeader MultiItem">
                <h3
                  className={`${activeSegment === "login" && "ActiveSegment"}`}
                  onClick={() => switchSegment("login")}
                >
                  Login
                </h3>
                <h3
                  className={`${
                    activeSegment === "register" && "ActiveSegment"
                  }`}
                  onClick={() => switchSegment("register")}
                >
                  Register
                </h3>
              </div>
              <div className={`FormBody ${switchAnimation && "Animation"}`}>
                <div className="BodySegment Login">
                  <input
                    value={formData.password}
                    placeholder="Password"
                    type="password"
                    className="PrimaryInput"
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  {renderButton(
                    "Login",
                    login,
                    !formIsValid || isLoading || formData.password == ""
                  )}
                </div>
                <div className="BodySegment Register">
                  <input
                    value={formData.password}
                    placeholder="Password"
                    type="password"
                    className="PrimaryInput"
                    onFocus={() => setOpenPassPopper(true)}
                    onBlur={() => setOpenPassPopper(false)}
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                      setOpenPassPopper(e.target.value == "" ? false : true);
                    }}
                  />
                  <input
                    value={confirmPassRegister}
                    placeholder="Confirm Password"
                    type="password"
                    className="PrimaryInput"
                    onChange={(e) => setConfirmPassRegister(e.target.value)}
                  />
                  {renderButton(
                    "Register",
                    register,
                    !formIsValid ||
                      isLoading ||
                      confirmPassRegister != formData.password
                  )}
                </div>
              </div>
              <div
                className="FormFooter"
                onClick={() => switchRecoveryModal(true)}
              >
                <div className="Info ForgotPassText">
                  Forgot your password ?
                </div>
              </div>
            </div>
          )}
          {newPasswordModal && renderNewPasswordModal()}
          {verificationModal && renderVerificationModal()}
          {recoveryModal && renderRecoveryModal()}
        </Box>
      </Modal>
    </div>
  );
};

export default ConnectWallet;
