import { Box, Icon, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { getSymbolByFieldName } from "../../Services/Common";
import ContractService from "../../Services/ContractService";
import InventoryService from "../../Services/InventoryService";
import InventoryIcon from "../../assets/img/inventory-icon.png";
import "./Inverntory.scss";
import { userStore } from "../../redux/store";

export const Inventory = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingOverlay, setLoadingOverlay] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const [activeTab, setActiveTab] = useState<any>("1");
  const [products, setProducts] = useState<any>({
    in_game: [],
    out_game: [],
  });
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedProcess, setSelectedProcess] = useState<any>();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const tabHeader = [
    { name: "Market Agents", tab: "1" },
    { name: "Ships", tab: "2" },
  ];

  useEffect(() => {
    setUser(userStore.getState());
    const userSubs = userStore.subscribe(() => setUser(userStore.getState()));
    return () => {
      userSubs();
    };
  });

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    if (user) setAgents();
  }, [user]);
  const setAgents = () => {
    InventoryService.getMerchants(user)
      .then((res) => {
        products.in_game = res;
        setProducts(JSON.parse(JSON.stringify(products)));
        getMerchantsNfts();
      })
      .catch((e) => setLoading(false));
  };
  const setShips = () => {
    products.in_game = user.ships;
    setProducts(JSON.parse(JSON.stringify(products)));
    getMerchantsNfts();
  };

  const getMerchantsNfts = async () => {
    await ContractService.setUpContract().catch((e) => setLoading(false));
    let itemsRedeemed;
    try {
      itemsRedeemed = (
        await ContractService.getSolidities().WhaleIslandsNFTsSolidity.balanceOf(
          user.wallet
        )
      ).toString();
    } catch (error) {
      toast.error("Wrong Network!");
      setLoading(false);
      return;
    }
    if (!itemsRedeemed) return;
    if (Number(itemsRedeemed) > 0) {
      let userNFTs = [];

      for (let x = 0; x < Number(itemsRedeemed); x++) {
        let nft = (
          await ContractService.getSolidities().WhaleIslandsNFTsSolidity.tokenOfOwnerByIndex(
            user.wallet,
            x
          )
        ).toString();
        userNFTs.push((Number(nft) + 1).toString());
      }
      InventoryService.getProductsByNftIds(userNFTs)
        .then((data) => {
          products.out_game = data.filter(
            (item: any) =>
              !products.in_game.some((item2: any) => item2._id == item._id)
          );
          setProducts(JSON.parse(JSON.stringify(products)));
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
    setLoading(false);
  };

  const handleClick = (operation: string, product: any) => {
    product["operation"] = operation;
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleModalOkClick = async () => {
    setSelectedProcess(selectedProduct["operation"]);
    setLoadingOverlay(true);
    setOpenModal(false);
    if (activeTab === "1") {
      if (!user.merchants) user.merchants = [];
      if (selectedProduct["operation"] === "remove") {
        user.merchants = user.merchants.filter(
          (item: any) => item != selectedProduct._id
        );
        await InventoryService.setUserMerchant(user);
        products.out_game.push(selectedProduct);
        products.in_game = products.in_game.filter(
          (item: any) => item._id != selectedProduct._id
        );
      } else {
        if (user.merchants.length > 3) {
          toast.error("Maximum number exceeded!");
          return;
        }
        user.merchants.push(selectedProduct._id);
        await InventoryService.setUserMerchant(user);
        products.in_game.push(selectedProduct);
        products.out_game = products.out_game.filter(
          (item: any) => item._id != selectedProduct._id
        );
      }
      setProducts(JSON.parse(JSON.stringify(products)));
    }
    setSelectedProduct("");
    setSelectedProcess("");
    delete selectedProduct["operation"];
    setLoadingOverlay(false);
  };
  const handleChangeTab = (tab: string) => {
    if (!user) return;
    if (tab === activeTab) return;
    setLoading(true);
    setActiveTab(tab);
    if (tab === "1") {
      setAgents();
    } else {
      setShips();
      // setTimeout(() => {
      //   setProducts({
      //     in_game: [],
      //     out_game: [],
      //   });
      //   setLoading(false);
      // }, 500);
    }
  };

  return (
    <div className="InventoryComponent MainSection">
      {loadingOverlay && user && (
        <div className="LoadingOverlay">
          <SyncLoader color="white" size={17} margin={9} />
        </div>
      )}
      <img src={InventoryIcon} className="SectionIcon" />
      <h2 className="SectionTitle">Inventories</h2>
      <div className="Tab">
        <div className="TabHeader">
          {tabHeader.map((item, i) => (
            <div
              key={i}
              onClick={() => handleChangeTab(item.tab)}
              className={`${activeTab === item.tab && "active"}`}
            >
              <h4>{item.name}</h4>
            </div>
          ))}
        </div>

        <div className="TabBody">
          {!user ? (
            <>
              <h4 className="NotAuthorized">
                You must be logged in to view inventory.
              </h4>
            </>
          ) : (
            <>
              <div>
                {loading ? (
                  <div className="SkeletonTable">
                    <div className="SkeletonRow">
                      {/* <Skeleton
                    variant="text"
                    className="SkeletonText"
                    height={42}
                    width={200}
                  ></Skeleton> */}
                      {Array(3)
                        .fill(0)
                        .map((_, i) => (
                          <Skeleton
                            key={i}
                            variant="rectangular"
                            className="SkeletonRectangle"
                            height={50}
                          ></Skeleton>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    {Object.keys(products).map((productsKey: any, i) => (
                      <div key={i}>
                        {products[productsKey].length > 0
                          ? products[productsKey].map((item: any, i: any) => (
                              <div
                                className={`TableRow ${
                                  selectedProduct?._id === item?._id &&
                                  selectedProcess
                                    ? productsKey === "out_game"
                                      ? " AnimationGoesLeft "
                                      : " AnimationComesLeft"
                                    : "AnimationComesLeft"
                                }`}
                                key={i}
                              >
                                <div className="ImageCol">
                                  <div className="ImageThumbnail">
                                    {item?.image?.length < 10 ? (
                                      <img
                                        src="https://storage.opensea.io/0xabc7e6c01237e8eef355bba2bf925a730b714d5f/3768-1556543767.png"
                                        alt=""
                                      />
                                    ) : (
                                      // <img src={item.image} alt="" />
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="RowBody">
                                  <h3> {item?.name}</h3>
                                  <div className="Symboles">
                                    <span className="NftId">
                                      #{item.nft_id}
                                    </span>
                                    <div className="SymboleContent">
                                      <div className="ContentTop">
                                        {Object.keys(item).map((field) => {
                                          if (getSymbolByFieldName(field)) {
                                            return (
                                              <div
                                                className={`Symbol ${field}`}
                                                key={field}
                                              >
                                                <img
                                                  src={getSymbolByFieldName(
                                                    field
                                                  )}
                                                  alt=""
                                                />
                                                <span>{item[field]}</span>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                      <span className="SectionHeader">
                                        {productsKey
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div
                                  onClick={() =>
                                    handleClick(
                                      productsKey === "in_game"
                                        ? "remove"
                                        : "add",
                                      item
                                    )
                                  }
                                >
                                  <Icon>
                                    {productsKey === "in_game"
                                      ? "remove"
                                      : "add"}
                                  </Icon>
                                </div> */}
                              </div>
                            ))
                          : i == 0 && (
                              <div className="EmtyRow">Nothing to show</div>
                            )}

                        {i == 0 &&
                          products["in_game"].length > 0 &&
                          products["out_game"].length > 0 && <hr></hr>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box>
          <div className="ModalBox">
            <div className="FormHeader">
              <h3>{`${
                selectedProduct && selectedProduct["operation"] === "add"
                  ? ` Activate`
                  : `Deactivate`
              }`}</h3>
              <h6>
                Are you sure you want to
                {selectedProduct && selectedProduct["operation"] === "add"
                  ? ` activate it`
                  : ` deactivate it`}
                ?
              </h6>
            </div>
            <div className="FormBody">
              <button
                className="CancelButton"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
              <button className="OkButton" onClick={handleModalOkClick}>
                Ok
              </button>
            </div>
            <div className="FormFooter">
              <div className="Info">
                <Icon>info</Icon>
                You will
                {`${
                  selectedProduct && selectedProduct["operation"] === "add"
                    ? ` use '${selectedProduct?.name}' nft in game`
                    : ` remove '${selectedProduct?.name}' nft from the game`
                }`}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default Inventory;
