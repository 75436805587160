import "./Game.scss";
import { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import PlayIcon from "../../assets/img/play-icon.png";
import { configStore, userStore } from "../../redux/store";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

const Game = () => {
  const [user, setUser] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userSubs = userStore.subscribe(() => {
      setUser(userStore.getState());
    });
    const configSubs = configStore.subscribe(() => {
      setIsLoading(configStore.getState().game_status != "pending");
    });
    return () => {
      userSubs();
      configSubs();
    };
  }, []);

  return (
    <div className="Game">
      <div className={`MainSection GameContent ${user && "CanPlay"}`}>
        <img src={PlayIcon} className="SectionIcon" />
        <h2 className="SectionTitle">
          Welcome to Whale <br /> Islands
        </h2>

        {isLoading ? (
          <PuffLoader color="white" size={150} />
        ) : (
          <div className="ConnectButton">
            <ConnectWallet />
          </div>
        )}
      </div>
    </div>
  );
};

export default Game;
