import InfiniteScroll from "react-infinite-scroll-component";
import "./DataTable.scss";

declare type Fn = () => any;

export interface DataTableProps {
  tableHeader: string;
  headers: any[];
  rows: any[];
  scrollable?: boolean;
  unAuthorized?: boolean;
  hasMore?: boolean;
  fetchMoreData?: Fn;
}

export const DataTable = (props: DataTableProps) => {
  const fetchMoreDataMock = () => {};
  return (
    <div className="DataTableComponent">
      <h2>{props.tableHeader} </h2>
      <div className="TableHeaders">
        {props.headers.map((header, index) => (
          <div style={{ width: header.width }} key={index}>
            {header.headerName}
          </div>
        ))}
      </div>
      <div className={`TableRows ${props.scrollable && "OverflowHidden"}`}>
        {props.unAuthorized && (
          <span className="UnAuthorized">
            You are not authorized to view this data
          </span>
        )}

        {props.scrollable ? (
          <InfiniteScroll
            dataLength={props.rows.length}
            next={props.fetchMoreData || fetchMoreDataMock}
            hasMore={props.hasMore || false}
            loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
            height={400}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {props.rows.map((row, rowIndex) => (
              <div className="TableRow" key={rowIndex}>
                {props.headers.map((cell: any, cellIndex: number) => (
                  <div key={cellIndex} style={{ width: cell.width }}>
                    {row[cell.field]}
                  </div>
                ))}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          props.rows.map((row, rowIndex) => (
            <div className="TableRow" key={rowIndex}>
              {props.headers.map((cell: any, cellIndex: number) => (
                <div key={cellIndex} style={{ width: cell.width }}>
                  {row[cell.field]}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DataTable;
