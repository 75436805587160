export const shortenAddress = (address: string, chars = 5): string => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};
export const getSymbolByFieldName = (fieldName: any) => {
  let source: any;
  switch (fieldName) {
    case "speed":
      source = "/symboles/speed.png";
      break;
    case "storage":
    case "safe":
      source = "/symboles/safe.png";
      break;
    case "network":
      source = "/symboles/network.png";
      break;
    case "power":
      source = "/symboles/power.png";
      break;
  }
  return source;
};
