import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { ScaleLoader } from "react-spinners";
import InventoryService from "../../Services/InventoryService";
import { userStore } from "../../redux/store";
import Chart from "../../components/Chart/Chart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Icon, Modal, Popover, Tab } from "@mui/material";
import { shortenAddress } from "../../Services/Common";
import AuthService from "../../Services/AuthService";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { setUserAction } from "../../redux/user/actions";

const passwordReg = [
  {
    name: "uppercase",
    reg: /.*[A-Z].*/,
    desc: "At least one uppercase letter",
  },
  {
    name: "lowercase",
    reg: /.*[a-z].*/,
    desc: "At least one lowercase letter",
  },
  {
    name: "number",
    reg: /.*\d.*/,
    desc: "At least one number",
  },
  {
    name: "long",
    reg: /^.{6,}$/,
    desc: "At least 6 characters long",
  },
];
let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
var regPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

const Profile = () => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordAnim, setPasswordAnim] = useState(false);
  const [modals, setModals] = useState({
    changePassword: false,
    changeEmail: false,
    verifyOtp: false,
  });
  const [otp, setOtp] = useState("");

  const [openPassPopper, setOpenPassPopper] = useState(false);
  const [changePasswordValid, setChangePasswordValid] = useState(false);

  let [passValid, setPassValid] = useState<any>({
    uppercase: false,
    lowercase: false,
    number: false,
    long: false,
  });

  const [passwordData, setPasswordData] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  useEffect(() => {
    handleFormChange();
  }, [passwordData]);

  const handleFormChange = () => {
    for (let item of passwordReg) {
      if (item.reg.test(passwordData.newPass)) {
        setPassValid({ ...passValid, [item.name]: true });
        passValid = { ...passValid, [item.name]: true };
      } else {
        setPassValid({ ...passValid, [item.name]: false });
        passValid = { ...passValid, [item.name]: false };
      }
    }
    if (modals.changePassword && regPass.test(passwordData.newPass)) {
      setChangePasswordValid(true);
    } else {
      setChangePasswordValid(false);
    }
  };
  const renderPassPopper = () => {
    return passwordReg.map((item, index) => (
      <span key={index}>
        <Icon className={`${passValid[item.name] && "Checked"} PassIcon`}>
          {passValid[item.name] ? "check_circle" : "cancel"}
        </Icon>{" "}
        {item.desc}
      </span>
    ));
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    userStore.getState() && setAnchorEl(null);
  };
  let openPopup = Boolean(anchorEl);
  const id = openPopup ? "simple-popover" : undefined;
  useEffect(() => {
    setUser(userStore.getState());
    const userSubs = userStore.subscribe(() => setUser(userStore.getState()));
    return () => {
      userSubs();
    };
  }, []);

  const renderButton = (title: string, click: () => {}, disabled: boolean) => {
    return (
      <button className="SecondaryBtn" onClick={click} disabled={disabled}>
        {isLoading ? (
          <ScaleLoader
            color="white"
            height={20}
            width={2}
            radius={2}
            margin={2}
          />
        ) : (
          title
        )}
      </button>
    );
  };

  const changePassword = async () => {
    setIsLoading(true);
    try {
      await AuthService.changePassword(
        passwordData.currentPass,
        passwordData.newPass
      )
        .then((res: any) => {
          setIsLoading(false);
          setModals({ ...modals, changePassword: false });
          toast.success(res);
        })
        .catch((e) => {
          toast.error(e);
          setIsLoading(false);
        });
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const changeEmail = async () => {
    sendEmailChangeCode();
  };
  const sendEmailChangeCode = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.sendEmailChangeCode(email, "change_email");
      toast.success(res.data.message);
      modals.verifyOtp = true;
      modals.changeEmail = false;
      setModals(modals);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };
  const verifyCode = async () => {
    setIsLoading(true);
    try {
      const res = await AuthService.verifyCode(email, otp, "change_email");
      setModals({ ...modals, verifyOtp: false });
      toast.success(res.data.message);
    } catch (err: any) {
      console.log("ERR", err.response.data);
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  const logOut = () => {
    localStorage.removeItem("spicaToken");
    handleClose();
    userStore.dispatch(setUserAction(null));
    toast.success("Logged out successfully");
  };
  const handleOtpChange = (otp: string) => setOtp(otp);
  return (
    <>
      <div
        className="ShortenAddress"
        aria-describedby={id}
        onClick={handleClick}
      >
        {user && (
          <>
            <Icon>account_balance_wallet</Icon>
            <span className="WalletAdress">{shortenAddress(user.wallet)}</span>
          </>
        )}
      </div>
      <div>
        <Popover
          id={id}
          open={openPopup}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className="Popover">
            <div className="PopoverBody">
              <div
                className="PopoverItem"
                onClick={() => setModals({ ...modals, changePassword: true })}
              >
                <Icon>lock_reset</Icon> <span>Change Password</span>
              </div>
              <div
                className="PopoverItem"
                onClick={() => setModals({ ...modals, changeEmail: true })}
              >
                <Icon>mail</Icon> <span>Change Email</span>
              </div>
              <div className="PopoverItem" onClick={logOut}>
                <Icon>logout</Icon> <span>Logout</span>
              </div>
            </div>
          </div>
        </Popover>
      </div>
      <Modal
        open={modals.changePassword}
        onClose={() => setModals({ ...modals, changePassword: false })}
      >
        <Box>
          {openPassPopper && (
            <div className="PassPopper">{renderPassPopper()}</div>
          )}
          <div className="ModalBox">
            <div className="FormHeader">
              <h3 className="Active">Change Password</h3>
            </div>
            <div className="FormBody">
              <div className="BodySegment">
                <input
                  value={passwordData.currentPass}
                  placeholder="Current Password"
                  type="password"
                  className="PrimaryInput"
                  onChange={(e) =>
                    setPasswordData({
                      ...passwordData,
                      currentPass: e.target.value,
                    })
                  }
                />
                <input
                  value={passwordData.newPass}
                  placeholder="New Password"
                  type="password"
                  className="PrimaryInput"
                  onFocus={() => setOpenPassPopper(true)}
                  onBlur={() => setOpenPassPopper(false)}
                  onChange={(e) => {
                    setPasswordData({
                      ...passwordData,
                      newPass: e.target.value,
                    });
                    if (e.target.value == "") setOpenPassPopper(false);
                  }}
                />
                <input
                  value={passwordData.confirmPass}
                  placeholder="Confirm Password"
                  type="password"
                  className="PrimaryInput"
                  onChange={(e) =>
                    setPasswordData({
                      ...passwordData,
                      confirmPass: e.target.value,
                    })
                  }
                />
                {renderButton(
                  "Change Password",
                  changePassword,
                  !changePasswordValid ||
                    passwordData.newPass != passwordData.confirmPass ||
                    !passwordData.currentPass
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={modals.changeEmail}
        onClose={() => setModals({ ...modals, changeEmail: false })}
      >
        <Box>
          <div className="ModalBox">
            <div className="FormHeader">
              <h3 className="Active">Change Email</h3>
            </div>
            <div className="FormBody">
              <div className="BodySegment">
                <input
                  value={email}
                  placeholder="Email"
                  className="PrimaryInput"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {renderButton(
                  "Change Email",
                  changeEmail,
                  !regEmail.test(email)
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={modals.verifyOtp}
        onClose={() => setModals({ ...modals, verifyOtp: false })}
      >
        <Box>
          <div className="ModalBox">
            <div className="FormHeader">
              <h3 className="Active">Enter Verification Code</h3>
            </div>
            <div className="FormBody">
              <div className="BodySegment">
                <OtpInput
                  inputStyle={{
                    width: "40px",
                    height: "41px",
                    margin: "0 6px",
                    background: "var(--theme-background)",
                    border: "2px solid var(--secondary-color-light)",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    fontSize: "1.2rem",
                    fontWeight: "600",
                  }}
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={5}
                  isInputNum
                />
                {renderButton(
                  "Verify Code",
                  verifyCode,
                  otp.length < 5 || isLoading
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Profile;
