import axios from "axios";

export class InventoryService {
  getMerchants = async (user: any) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/fn-execute/marketAgent/getAll/v2`,
        {
          headers: {
            Authorization: `IDENTITY ${user.token}`,
          },
        }
      )
      .then((res) => res.data?.data?.agents);
  };
  getProductsByNftIds = async (ids: any): Promise<any> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/getProductsByNftIds`, {
        params: {
          ids,
        },
      })
      .then((data) => data.data);
  };
  setUserMerchant = (user: any) => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/bucket/61d6e14d7b6080002dc77251/data/${user._id}`,
        {
          merchants: user.merchants,
        },
        {
          headers: {
            Authorization: `IDENTITY ${user.token}`,
          },
        }
      )
      .then((data) => data.data[0]);
  };
  getUserOrders = (pagination: any) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/bucket/61d6e15c7b6080002dc7726d/data`,
        {
          headers: {
            Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
          },
          params: {
            relation: ["city", "product"],
            limit: pagination.limit,
            skip: pagination.skip,
            sort: { _id: -1 },
          },
        }
      )
      .then((data) => data.data);
  };
  getLeaderBord = (pagination: any) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/player/leaderBord`, {
        params: {
          limit: pagination.limit,
          skip: pagination.skip,
        },
      })
      .then((data) => data.data);
  };

  getUserBalanceHistory = async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/fn-execute/player/balanceHistory`,
        {
          headers: {
            Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
          },
        }
      )
      .then((data) => data.data);
  };
  getAgentPoints = async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/marketAgent/powers`, {
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      })
      .then((data) => data.data);
  };
  getCityTrades = async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/city/trades`, {
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      })
      .then((data) => data.data);
  };
}

export default new InventoryService();
