import { toast } from "react-toastify";
import ContractService from "./ContractService";
import axios from "axios";

export class WislInteractionService {
  getTreasureSignerContract = () =>
    ContractService.getConnectedContract(
      ContractService.getSolidities().TRSolidity
    );

  getWlsignerConnectedContract = () =>
    ContractService.getConnectedContract(
      ContractService.getSolidities().WLSolidity
    );

  cancelProvision = async (provisionId: string, errorMessage: string) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/fn-execute/player/provision/cancel`,
        {
          provisionId,
          errorMessage,
        },
        {
          headers: {
            Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
          },
        }
      )
      .then((data) => data.data[0]);
  };

  setProvisionsToConfirm = (provisions: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/player/provision/confirm`,
      {
        provisionIds: provisions,
      },
      {
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      }
    );
  };

  paymentFn = async (data: any) => {
    return this.getTreasureSignerContract()
      .makePayment(
        data.provisionIds,
        ContractService.parseEther(data.wislAmount)
      )
      .catch((err: any) => {
        for (const provision of data.provisionIds) {
          this.cancelProvision(provision, JSON.stringify(err));
        }
        toast.error(
          err.data
            ? err.data.message.split(":")[
                err.data.message.split(":").length - 1
              ]
            : err.message.split(":")[err.message.split(":").length - 1]
        );
      });
  };
  paymentWisl = async (account: string, data: any) => {
    
    data.provisionIds = data.provisionIds.split(",");
    const confirms = await this.setProvisionsToConfirm(data.provisionIds);
    data.provisionIds = confirms.data.data.provisionIds;

    if (!data.provisionIds.length) {
      toast.info("There are no active provisions");
      return;
    }
    const allowance = await this.getWlsignerConnectedContract()
      .allowance(
        account,
        ContractService.getContractJson().contracts.Treasure.address
      )
      .catch((e: any) => console.log("e :", e));

    if (
      Number(ContractService.formatEther(allowance)) > Number(data.wislAmount)
    )
      await this.paymentFn(data);
    else
      await this.getWlsignerConnectedContract()
        .approve(
          ContractService.getContractJson().contracts.Treasure.address,
          ContractService.parseEther((100_000_000).toString())
        )
        .then(() => {
          ContractService.getSolidities().WLSolidity.on(
            "Approval",
            async (owner: any, spender: any) => {
              if (
                owner == account &&
                spender ==
                  ContractService.getContractJson().contracts.Treasure.address
              ) {
                ContractService.getSolidities().WLSolidity.removeAllListeners(
                  "Approval"
                );
                await this.paymentFn(data);
              }
            }
          );
        })
        .catch((e: any) => {
          console.log("e :", e);
        });
  };
}

export default new WislInteractionService();
