import {
  SET_LATEST_VERSION,
  SET_USED_VERSION,
  SET_MAINTANCE_MODE,
  SET_GAME_STATUS,
} from "./types";

export const setLatestVersionAction = (version: string) => {
  return {
    type: SET_LATEST_VERSION,
    data: version,
  };
};
export const setUsedVersionAction = (version: string) => {
  return {
    type: SET_USED_VERSION,
    data: version,
  };
};
export const setInMaintanceAction = (in_maintance: string) => {
  return {
    type: SET_MAINTANCE_MODE,
    data: in_maintance,
  };
};
export const setGameStatusAction = (game_status: string) => {
  return {
    type: SET_GAME_STATUS,
    data: game_status,
  };
};
