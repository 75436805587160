import { useEffect, useState } from "react";
import Chart from "../../components/Chart/Chart";
import DataTable from "../../components/DataTable/DataTable";
import { userStore } from "../../redux/store";
import InventoryService from "../../Services/InventoryService";
import "./Dashboard.scss";

const Dashboard = () => {
  const [cityTrades, setCityTrades] = useState<any>([]);
  const [orders, setOrders] = useState<any>([]);
  const [hasMoreOrder, setHasMoreOrder] = useState<boolean>(false);
  const [hasMoreBoard, setHasMoreBoard] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [balanceChartData, setBalanceChartData] = useState<any>();
  const [leaderBord, setLeaderBord] = useState<any>([]);

  const [paginationOrder, setPaginationOrder] = useState({
    limit: 10,
    skip: 0,
  });
  const [paginationLeaderBord, setPaginationLeaderBord] = useState({
    limit: 10,
    skip: 0,
  });
  const orderType: any = {
    0: "Buy",
    1: "Sell",
    2: "In Provision",
  };

  useEffect(() => {
    getCityTrades();
    getLeaderBord();
    setUser(userStore.getState());
    const userSubs = userStore.subscribe(() => setUser(userStore.getState()));
    return () => {
      userSubs();
    };
  }, []);

  useEffect(() => {
    if (user) {
      getOrders();
      getBalanceChartData();
    } else {
      setBalanceChartData(null);
      setOrders([]);
    }
  }, [user]);

  const getBalanceChartData = () => {
    InventoryService.getUserBalanceHistory().then((res) => {
      setBalanceChartData({
        data: res.datas,
        labels: res.labels,
        title: "Last 10 Day Balance",
      });
    });
  };
  const getOrders = () => {
    InventoryService.getUserOrders(paginationOrder).then((res) => {
      setHasMoreOrder(res.length == paginationOrder.limit);
      res.forEach((order: any) => {
        order.id = order._id;
        order.city = order.city.title;
        order.product = order.product.title;
        order.price = Number(order.price).toFixed(3);
        order.amount = Number(order.amount).toFixed(3);
        order.date =
          new Date(order.created_at).toLocaleDateString() +
          " " +
          new Date(order.created_at).toLocaleTimeString();
        order.order_type = orderType[order.order_type];
        return order;
      });
      setOrders(JSON.parse(JSON.stringify([...orders, ...res])));
    });
  };
  const getLeaderBord = () => {
    InventoryService.getLeaderBord(paginationLeaderBord).then((res) => {
      setHasMoreBoard(res.length == paginationLeaderBord.limit);
      res.forEach((item: any, i: number) => {
        item.order = "#" + (leaderBord.length + i + 1);
      });
      setLeaderBord(JSON.parse(JSON.stringify([...leaderBord, ...res])));
    });
  };

  const fetchMoreOrderData = () => {
    paginationOrder.skip += paginationOrder.limit;
    setPaginationOrder({ ...paginationOrder, skip: paginationOrder.skip });
    getOrders();
  };
  const fetchMoreBoardData = () => {
    paginationLeaderBord.skip += paginationLeaderBord.limit;
    setPaginationLeaderBord({
      ...paginationLeaderBord,
      skip: paginationLeaderBord.skip,
    });
    getLeaderBord();
  };

  const getCityTrades = () => {
    InventoryService.getCityTrades().then((res) => {
      res.forEach((item: any) => {
        item.in_day = Number(item.in_day.toFixed(3));
        item.in_hour =
          Number(item.in_hour.toFixed(3)) +
          " (%" +
          Number(item.trade_ratio.toFixed(2)) +
          ")";
        item.total_hourly_income = Number(item.total_hourly_income.toFixed(3));
      });
      setCityTrades(res);
    });
  };

  const cityTradeColumns = [
    { field: "title", headerName: "City", width: "15%" },
    { field: "in_day", headerName: "24h", width: "10%" },
    {
      field: "in_hour",
      headerName: "4h (%)",
      width: "20%",
    },
    { field: "total_agent", headerName: "Total Agent", width: "10%" },
    {
      field: "total_agent_power",
      headerName: "Total Agent Power",
      width: "15%",
    },
    {
      field: "total_hourly_income",
      headerName: "Wisl Dist. Through Agents (h)",
      width: "17.5%",
    },
  ];
  const orderColumns = [
    { field: "date", headerName: "Date", width: "20%" },
    { field: "city", headerName: "City", width: "10%" },
    { field: "order_type", headerName: "Order Type", width: "10%" },
    {
      field: "price",
      headerName: "Price",
      width: "15%",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: "15%",
    },
    {
      field: "product",
      headerName: "Product",
      width: "10%",
    },
    {
      field: "status_message",
      headerName: "Status",
      width: "20%",
    },
  ];
  const boardColumns = [
    {
      field: "order",
      headerName: "Order",
      width: "20%",
    },
    {
      field: "player",
      headerName: "Player",
      width: "60%",
    },
    {
      field: "balance",
      headerName: "Balance",
      width: "25%",
    },
  ];

  return (
    <div className="DashboardComponent">
      <DataTable
        tableHeader={"City Trades"}
        headers={cityTradeColumns}
        rows={cityTrades}
      ></DataTable>
      <DataTable
        tableHeader={"Order History"}
        headers={orderColumns}
        rows={orders}
        scrollable={orders.length > 0}
        unAuthorized={!user}
        hasMore={hasMoreOrder}
        fetchMoreData={fetchMoreOrderData}
      ></DataTable>
      {/* <div className="ChartContainer">
        <h2>Analysis</h2>
        <div className="Charts">
          {balanceChartData && <Chart chartData={balanceChartData}></Chart>}
        </div>
      </div> */}
      <DataTable
        tableHeader={"Leaderboard"}
        headers={boardColumns}
        rows={leaderBord}
        scrollable={leaderBord.length > 0}
        hasMore={hasMoreBoard}
        fetchMoreData={fetchMoreBoardData}
      ></DataTable>
    </div>
  );
};

export default Dashboard;
