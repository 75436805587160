import * as Bucket from "@spica-devkit/bucket";

export class DevkitBucketService {
  getRemoteConfig(): any {
    Bucket.initialize({
      identity: localStorage.getItem("spicaToken") || "",
      publicUrl: process.env.REACT_APP_API_URL,
    });
    return Bucket.data.realtime.getAll(
      process.env.REACT_APP_CONFIG_BUCKET_ID || "",
      {
        filter: {
          key: { $in: ["remote_config", "latest_version", "in_maintance"] },
        },
      }
    );
  }
  getUserRealtime(): any {
    Bucket.initialize({
      identity: localStorage.getItem("spicaToken") || "",
      publicUrl: process.env.REACT_APP_API_URL,
    });
    return Bucket.data.realtime.getAll(
      process.env.REACT_APP_USER_BUCKET_ID || ""
    );
  }
}

export default new DevkitBucketService();
