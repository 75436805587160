import "./Home.scss";
import Game from "../../components/Game/Game";
import Inventory from "../../components/Inventory/Inventory";
import Dashboard from "../Dashboard/Dashboard";
import DevkitBucketService from "../../Services/DevkitBucketService";
import { useEffect, useState } from "react";
import { configStore, userStore } from "../../redux/store";
import {
  setLatestVersionAction,
  setInMaintanceAction,
} from "../../redux/config/actions";

const Home = () => {
  const [user, setUser] = useState<any>(null);
  const [latestVersion, setLatestVersion] = useState<any>();

  useEffect(() => {
    setUser(userStore.getState());
    const userSubs = userStore.subscribe(() => setUser(userStore.getState()));
    return () => {
      userSubs();
    };
  }, []);

  useEffect(() => {
    if (user) {
      DevkitBucketService.getRemoteConfig().subscribe((res: any) => {
        if (!res) return;
        setLatestVersion(
          res.filter((item: any) => item.key == "latest_version")[0]
        );
        configStore.dispatch(
          setInMaintanceAction(
            res.filter((item: any) => item.key == "in_maintance")[0]?.value
          )
        );
      });
    }
  }, [user]);

  useEffect(() => {
    if (latestVersion)
      configStore.dispatch(setLatestVersionAction(latestVersion.value));
  }, [latestVersion]);

  return (
    <div className="Home ComponentContainer">
      <div className="Contents">
        <Inventory></Inventory>
        <Game />
      </div>
      <div className="Contents">
        <Dashboard></Dashboard>
      </div>
    </div>
  );
};

export default Home;
