import {
  SET_GAME_STATUS,
  SET_LATEST_VERSION,
  SET_MAINTANCE_MODE,
  SET_USED_VERSION,
} from "./types";

export function configReducer(state: any, action: any) {
  if (!state)
    state = { versions: { latest: "", used: "" }, game_status: "pending" };

  if (action.type === SET_LATEST_VERSION) {
    state.versions.latest = action.data;
    return state;
  }
  if (action.type === SET_USED_VERSION) {
    state.versions.used = action.data;
    return state;
  }
  if (action.type === SET_MAINTANCE_MODE) {
    state.inMaintance = action.data;
    return state;
  }
  if (action.type === SET_GAME_STATUS) {
    state.game_status = action.data;
    return state;
  }

  return state;
}
