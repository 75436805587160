import axios from "axios";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import detectEthereumProvider from "@metamask/detect-provider";

export class ContractService {
  private provider: any;
  private signer: any;
  private contract: any;
  private maSolidity: any;
  private mpSolidity: any;
  private wlSolidity: any;
  private trSolidity: any;
  private whaleIslandsNFTsSolidity: any;

  setUpContract = async () => {
    if (!this.contract) await this.getContract();
    return this.setProviderData();
  };
  sendConnectionRequest = async () => {
    try {
      this.provider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );
      (window as any).ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA869",
            rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
            chainName: "Avalanche FUJI C-Chain",

            // chainId: "0xA86A",
            // rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            // chainName: "Avalanche Mainnet C-Chain",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://snowtrace.io/"],
          },
        ],
      });

      (window as any).ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      (window as any).ethereum.on("accountsChanged", () => {
        localStorage.removeItem("spicaToken");
        window.location.reload();
      });

    } catch (error) {
      console.log("missing provider");
      return;
    }
    return this.provider.send("eth_requestAccounts", []);
  };

  // getContract = async () => {
  //   const conf = await axios
  //     .get(`${process.env.REACT_APP_API_URL}/bucket/620237d554c04b002e78fa29/data`, {
  //       params: {
  //         filter: { key: process.env.REACT_APP_CONTRACT_TYPE },
  //       },
  //       headers: {
  //         Authorization: `APIKEY ${process.env.REACT_APP_CONFIG_APIKEY}`,
  //       },
  //     })
  //     .then((data) => data.data[0]);

  //   const deploymentFji = await axios.get(conf.file as any);
  //   this.contract = deploymentFji.data;
  // };
  getContract = async () => {
    // this.contract = deploymentLocal;
    // return deploymentLocal;
    const conf = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/bucket/620237d554c04b002e78fa29/data`,
        {
          params: {
            filter: { key: process.env.REACT_APP_CONTRACT_TYPE },
          },
          headers: {
            Authorization: `APIKEY ${process.env.REACT_APP_CONFIG_APIKEY}`,
          },
        }
      )
      .then((data) => data.data[0])
      .catch((e) => console.log("e :", e));
    if (!conf) {
      return {};
    }
    const deploymentFji = await axios.get(
      conf.file?.replace("/download", "") as any
    );
    this.contract = deploymentFji.data;
    return deploymentFji.data;
  };

  withdrawWisl = async (amount: number, token: string) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/fn-execute/withdrawWisl`,
        {
          amount,
        },
        {
          headers: {
            Authorization: `IDENTITY ${token}`,
          },
        }
      )
      .then((data) => data.data[0]);
  };

  getUser = async (user: any) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/bucket/61d6e14d7b6080002dc77251/data/${user._id}`,
        {
          headers: {
            Authorization: `IDENTITY ${user.token}`,
          },
        }
      )
      .then((data) => data.data);
  };


  setProviderData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        this.provider = new ethers.providers.Web3Provider(
          (window as any).ethereum
        );
      } catch (error) {
        toast.error("Missing Provider!", {
          position: "top-center",
        });

        reject(false);
        return;
      }
      this.signer = this.provider.getSigner();
      await this.getSolidity().catch((e: any) => {
        toast.error("Something went wrong on solidity side!", {
          position: "top-center",
        });
        reject(false);
      });
      try {
        await this.getSolidities().WLSolidity.symbol();
      } catch (error) {
        reject(false);
      }
      resolve(true);
    });
  };

  getSolidity = async () => {
    this.whaleIslandsNFTsSolidity = new ethers.Contract(
      this.contract.contracts.WhaleIslandsNFTs.address,
      this.contract.contracts.WhaleIslandsNFTs.abi,
      this.provider
    );
    // this.mpSolidity = new ethers.Contract(
    //   this.contract.contracts.Marketplace.address,
    //   this.contract.contracts.Marketplace.abi,
    //   this.provider
    // );
    this.wlSolidity = new ethers.Contract(
      this.contract.contracts.WISL.address,
      this.contract.contracts.WISL.abi,
      this.provider
    );
    this.trSolidity = new ethers.Contract(
      this.contract.contracts.Treasure.address,
      this.contract.contracts.Treasure.abi,
      this.provider
    );
  };
  getSolidities = () => {
    return {
      WhaleIslandsNFTsSolidity: this.whaleIslandsNFTsSolidity,
      // MPSolidity: this.mpSolidity,
      WLSolidity: this.wlSolidity,
      TRSolidity: this.trSolidity,
    };
  };
  getSigner = () => {
    return this.signer;
  };
  parseEther = (value: string) => {
    return ethers.utils.parseEther(value);
  };
  formatEther = (value: string) => {
    return ethers.utils.formatEther(value);
  };

  getContractJson = () => {
    return this.contract;
  };
  getConnectedContract = (connectedContract: any) => {
    return connectedContract.connect(this.signer);
  };
  getMinter = () => {
    return "0xecde74914988d394964746bb15b9a9ff86107642";
  };
  getProvider = () => {
    return this.provider;
  };
    addToken = async (
    tokenAddress: any,
    tokenSymbol: any,
    tokenDecimals: any
  ) => {
    const providerEth: any = await detectEthereumProvider();
    new ethers.providers.Web3Provider((window as any).ethereum);
    await providerEth.sendAsync(
      {
        method: "metamask_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
          },
        },
        id: Math.round(Math.random() * 100000),
      },
      (err: any, added: any) => {
        if (err || "error" in added) {
          toast.error("There was a problem while adding the token!", {
            position: "top-center",
          });
          return;
        }
      }
    );
  };
  addWisltoMetamask = async () => {
    await this.addToken(this.getSolidities().WLSolidity.address, "WISL", 18);
  };
}
export default new ContractService();
