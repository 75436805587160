import "./Footer.scss";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";

export const Footer = () => {
  return (
    <div className="FooterComponent">
      <div className="Content">
        <div className="Brand">
          <img src="/logo.png" alt="" />
          <div>
            Whale Islands, The First Decentralized Resource Management Trade in
            game, earn in real.
          </div>
        </div>
        <div className="Itemlist">
          <ul>
            <li>
              <a target="_blank" href="https://twitter.com/wislapp">
                <TwitterIcon></TwitterIcon>
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://t.me/+ZXBNUsJycnJkNWE0">
                <TelegramIcon></TelegramIcon>
                <span>Telegram</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://discord.gg/APtrFfSF7u">
                <img src="/discort.svg" alt="" />
                <span>Discord</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="Itemlist">
          <ul>
            <li>
              <a target="_blank" href="https://whaleislands.com/">
                <span>Official</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://game.whaleislands.com">
                <span>Game</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://docs.whaleislands.com">
                <span>Whitepaper</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://whaleislands.com/faucet">
                <span>Faucet</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Footer;
