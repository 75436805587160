import { useEffect, useState } from "react";
import "./App.scss";
import { Navbar } from "./components/Navbar/Navbar";
import PuffLoader from "react-spinners/PuffLoader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import { Box, Icon, LinearProgress, Modal } from "@mui/material";
import { configStore, userStore } from "./redux/store";
import { setUsedVersionAction } from "./redux/config/actions";
import WislInteractionService from "./Services/WislInteractionService";
import DevkitBucketService from "./Services/DevkitBucketService";
import { toast } from "react-toastify";

function App() {
  const [startGame, setStartGame] = useState<boolean>(false);
  const [inMaintance, setInMaintance] = useState<boolean>(false);

  const [versions, setVersions] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  let [progress, setProgress] = useState(5);
  let hasPlaying = false;

  useEffect(() => {
    let timer: any;
    const configSubs = configStore.subscribe(() => {
      handleGameStatus(configStore.getState().game_status);
      const versions = configStore.getState().versions;
      setVersions(versions);

      if (
        versions.latest &&
        versions.used &&
        versions.latest !== versions.used &&
        !window.location.href.includes("latest")
      ) {
        setOpenModal(true);

        timer = setInterval(() => {
          if (progress < 100) {
            progress += 5;
            setProgress(progress);
          } else {
            clearInterval(timer);
            refreshPage();
          }
        }, 1000);
      } else setOpenModal(false);

      // Maintance
      if (!inMaintance && configStore.getState().inMaintance == "true") {
        setInMaintance(true);
        setOpenModal(false);
        (window as any).setMaintance(true);
      } else {
        setInMaintance(false);
        (window as any).setMaintance(false);
      }
      //
    });
    return () => {
      configSubs();
      if (timer) clearInterval(timer);
    };
  }, []);

  const refreshPage = () => window.location.reload();

  const maintanceModal = () => {
    return (
      <Modal
        className="Blured"
        open={inMaintance}
        onClose={() => setInMaintance(true)}
      >
        <Box>
          <div className="ModalBox">
            <div className="FormHeader">
              <h3>We are currently in maintenance</h3>
              <h6>We will open in a few minutes</h6>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const handleGameStatus = (status: string) => {
    if (status == "playing" && !hasPlaying) {
      setStartGame(true);
      hasPlaying = true;

      (window as any).startTheGame(userStore.getState());

      window.addEventListener(
        "VersionEvent",
        function (evt: any) {
          configStore.dispatch(setUsedVersionAction(evt.detail));
        },
        false
      );
      window.addEventListener(
        "TransferWisl",
        function (evt: any) {
          WislInteractionService.paymentWisl(
            userStore.getState().wallet,
            evt.detail
          );
        },
        false
      );

      DevkitBucketService.getUserRealtime().subscribe((res: any) => {
        if (userStore.getState().window_id != res[0].window_id) {
          toast.info("You have been disconnected from the game");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    }
  };

  const versionModal = () => {
    return (
      <Modal open={openModal} onClose={() => setOpenModal(true)}>
        <Box>
          <div className="ModalBox">
            <LinearProgress
              className="ModalProgress"
              value={progress}
              variant="determinate"
            />
            <div className="FormHeader">
              <h3>A new version of the game is available</h3>
              <h6>Refresh the page?</h6>
            </div>
            <div className="FormBody">
              <button className="OkButton" onClick={refreshPage}>
                Ok
              </button>
            </div>
            <div className="FormFooter">
              <div className="Info">
                <Icon>info</Icon>
                <div className="InfoItem">
                  <span>Used : {versions?.used} </span>
                  <span>Latest : {versions?.latest}</span>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const appContent = () => {
    return (
      <div>
        <div className={!startGame ? "FullScreen" : "BackScreen"}>
          <img
            className="ComponentBg"
            src="https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d7f60e1e7c2f002d4bdb63?alt=media&timestamp=1641543183240"
          />

          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />}></Route>
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
        <div className={`Loader ${startGame ? "FullScreen" : "BackScreen"}`}>
          <PuffLoader color="white" size={500} />
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      {!inMaintance ? appContent() : ""}

      {maintanceModal()}
      {versionModal()}
    </div>
  );
}

export default App;
