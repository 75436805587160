import {SET_USER} from './types';

export function userReducer(state: any, action: any) {
  if (action.type === SET_USER) {
    state = action.data;
    return state;
  } 
  
  return state;
}
