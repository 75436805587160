import * as Identity from "@spica-devkit/identity";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export class AuthService {
  login = async (password: string, wallet: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/fn-execute/login`, {
      password: password,
      wallet: wallet,
    });
  };

  getUuid = () => uuidv4();

  register = async (password: string, wallet: string) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/register_v2`,
      {
        password: password,
        wallet: wallet,
      }
    );
  };

  verifyCode = async (email: string, code: string, action: string) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/verifyCode`,
      {
        email: email,
        code: code,
        action: action,
      },
      {
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      }
    );
  };

  setUserWindowId = async () => {
    const winId = this.getUuid();
    const decodedToken: any = await this.getDecodedToken();
    if (!decodedToken) {
      return Promise.reject("Token not decoded");
    }
    return axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/player/winId`, {
        params: { winId },
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      })
      .then(() => winId);
  };

  changePassword = async (currentPassword: string, newPassword: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        Identity.initialize({
          identity: localStorage.getItem("spicaToken") || "",
          publicUrl: process.env.REACT_APP_API_URL,
        });
      } catch (error) {
        reject("Invalid token");
      }

      const identity: any = await this.verifyToken().catch((e) => reject(e));

      if (!identity) return reject("Invalid token");

      const loggedUser = await Identity.login(
        identity.identifier,
        currentPassword
      ).catch((e) => reject("Current password is invalid"));

      if (!loggedUser) return reject("Current password is invalid");

      const updatedUser = await Identity.update(identity._id, {
        identifier: identity.identifier,
        password: newPassword,
      }).catch((e) => reject("Failed to update password"));

      if (!updatedUser) return reject("Failed to update password");
      resolve("Password changed successfully");
    });
  };
  recoveryPassword = async (email: string) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/recoveryPassword`,
      {
        email: email,
      }
    );
  };

  setNewPassword = async (credentials: {
    email: string;
    password: string;
    wallet: string;
  }) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/setNewPassword`,
      credentials
    );
  };

  resendVerificationCode = async (email: string, action: string) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/resendVerificationCode`,
      {
        email: email,
        action: action,
      }
    );
  };
  sendEmailChangeCode = async (email: string, action: string) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/fn-execute/sendEmailChangeCode`,
      {
        email: email,
        action: action,
      }
    );
  };

  getUser = async () => {
    const decodedToken: any = await this.getDecodedToken();
    if (!decodedToken) {
      return Promise.reject("Token not decoded");
    }
    return axios
      .get(`${process.env.REACT_APP_API_URL}/fn-execute/user`, {
        headers: {
          Authorization: `IDENTITY ${localStorage.getItem("spicaToken")}`,
        },
      })
      .then((data) => data.data);
  };

  getDecodedToken = async () => {
    let decodedToken;
    let token = localStorage.getItem("spicaToken");
    if (token) {
      decodedToken = await this.verifyToken();
    }

    return decodedToken;
  };

  verifyToken = async () => {
    return Identity.verifyToken(
      localStorage.getItem("spicaToken") as string,
      process.env.REACT_APP_API_URL
    );
  };
}

export default new AuthService();
