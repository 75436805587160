import "./Navbar.scss";
import Logo from "../../assets/img/logo.png";
import Profile from "../../pages/Profile/Profile";

export const Navbar = () => {
  return (
    <div className="NavbarContainer">
      <div className="Links">
        <img className={`Logo`} src={Logo} />
        <Profile></Profile>
      </div>
    </div>
  );
};
